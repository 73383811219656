.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.error-message {
  position: fixed;
  top: 5px;
  left: 10%;
  width: 80%;
  z-index: 9999;
  border-radius: 0px;
}
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 90px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  z-index: 99;
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 11.5rem;
    padding: 0;
  }
}

.navbar {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 767.98px) {
  .navbar {
    top: 0;
    position: sticky;
    z-index: 999;
  }
}

.sidebar .nav-link {
  color: #333;
}

.sidebar .nav-link.active {
  color: #0d6efd;
}

html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 60px; /* Margin bottom by footer height */
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f7f7f7;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}

#chartdiv {
  width: 100%;
  height: 180px;
}

#mapid {
  float: left;
  height: 70vh;
  width: 100%;
}

/* .leaflet-popup-content-wrapper {
  background: #1565c0;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}

.leaflet-popup-content-wrapper a {
  color: white;
  color: rgba(255, 255, 255, 0.5);
}

.leaflet-popup-tip-container {
  width: 30px;
  height: 15px;
}

.leaflet-popup-tip {
  background: #1565c0;
} */

.info {
  width: 300px;
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend {
  width: 150px;
}

.biglegend {
  width: 200px;
}

.circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.alarm {
  background-color: #ffeb3b;
}

.normal {
  background-color: #8bc34a;
}

.alarmAlt {
  background-color: #fdd835;
}

.normalAlt {
  background-color: #7cb342;
}

.prsone {
  color: dodgerblue;
  font-style: italic;
}

.prstwo {
  color: red;
  font-weight: bold;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

[data-theme="dark"] {
  background-color: #111 !important;
  color: #eee;
}

[data-theme="dark"] .bg-black {
  background-color: #fff !important;
}

[data-theme="dark"] .bg-dark {
  background-color: #eee !important;
}

[data-theme="dark"] .bg-light {
  background-color: #222 !important;
}

[data-theme="dark"] .bg-white {
  background-color: #000 !important;
}

.table-warning {
  --bs-table-bg: #FFEE58;
  --bs-table-striped-bg: #FDD835;
  --bs-table-active-bg: #FFEB3B;
  --bs-table-hover-bg: #FFF176;
}

/* .table-danger {
  --bs-table-bg: #EF5350;
  --bs-table-striped-bg: #E53935;
  --bs-table-active-bg: #F44336;
  --bs-table-hover-bg: #E57373;
  --bs-table-bg: #9E9E9E;
  --bs-table-striped-bg: #757575;
  --bs-table-active-bg: #BDBDBD;
  --bs-table-hover-bg: #E0E0E0; 
} */